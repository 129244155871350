import { useState, Fragment } from 'react';
import { t } from 'ttag';
import moment from 'moment';
import { ReactComponent as IconArrowDown } from 'static/Common/icon-arrow-down.svg';
import { Input } from 'components/common/Input/index.js';
import Dropdown from 'components/common/Dropdown/index.js';
import TextAndFileInput from 'components/Vehicle/TextAndFileInput/index.js';
import './main.scss';

const FieldMeta = ({ text }) => text && <span className='field-meta'>({text})</span>;

const Fieldset = ({ icon, label, fields, values, vehicle, onChange, disabled, lists, errors, i18n, sources }) => {
    const [open, setOpen] = useState(false);

    const handleChange = e => onChange(e.target.name, e.target.value);
    const handleFileChange = (name, file) => onChange(name, file);
    const handleDropdownChange = (name, value) => onChange(name, value);

    return (
        <fieldset className={`vehicle-fieldset ${open ? 'open' : ''}`}>
            <button type='button' onClick={() => setOpen(!open)}>
                {icon}
                <span className='vehicle-fieldset-label'>
                    {label}
                </span>
                <IconArrowDown />
            </button>
            <div className='vehicle-fieldset-wrap'>
                {Object.keys(fields).map(field => {
                    const { EDITABLE, WIDE, FILE, SOURCE, EDITED, BOOL, OPTIONS, TYPE, STEP } = fields[field];

                    const props = {
                        key: field,
                        type: TYPE || 'text',
                        name: field,
                        value: (values[field] === undefined ? vehicle[field] : values[field]) ?? '',
                        disabled: !EDITABLE || disabled,
                        label: (
                            <>
                                {i18n[field]}<br />
                                <span className='data-source'>
                                    {t`SOURCE`}: {i18n[sources[field] || 'UNKNOWN'] || i18n.UNKNOWN}
                                </span>
                            </>
                        ),
                        className: WIDE ? 'full-width' : '',
                        error: errors[field],
                        min: 0,
                        step: STEP || undefined,
                    };

                    if(props.value && (TYPE === 'date')) {
                        // Format date fields
                        props.value = moment(values[field]).format('YYYY-MM-DD');
                    }

                    let meta = null;
                    if(EDITED) meta = t`VALUE_EDITED_BY_USER`;
                    if(vehicle[SOURCE]) meta = `${t`DATA_SOURCE`}: ${vehicle[SOURCE]}`;
                    if(FILE && vehicle[FILE]) meta = t`VALUE_NOT_ORIGINAL`;

                    let Result = null;

                    if(FILE) {
                        Result = (
                            <TextAndFileInput
                                {...props}
                                label={<>{props.label} <FieldMeta text={meta} /></>}
                                onChange={handleChange}
                                onFileChoose={e => handleFileChange(FILE, e.target.files[0])}
                                fileError={errors[FILE]}
                            />
                        );
                    } else if(BOOL) {
                        Result = (
                            <Dropdown
                                {...props}
                                className={`form-label ${props.className}`}
                                options={[{ value: true, label: t`YES` }, { value: false, label: t`NO` }]}
                                onChange={value => handleDropdownChange(field, value)}
                            />
                        );
                    } else if(OPTIONS) {
                        Result = (
                            <div key={field} className='form-label dropdown-container'>
                                <Dropdown
                                    {...props}
                                    label={<>{props.label} <FieldMeta text={meta} /></>}
                                    options={lists[OPTIONS] || []}
                                    onChange={value => handleDropdownChange(field, value)}
                                />
                            </div>
                        );
                    } else {
                        Result = (
                            <Input {...props} onChange={handleChange}>
                                {meta}
                            </Input>
                        );
                    }

                    return Result;
                })}
            </div>
        </fieldset>
    );
};

export default Fieldset;
